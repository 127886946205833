.icon {
  fill: black;
}
.primary {
  fill: #1b5e20;
}
.alert {
  fill: #ffffff;
  background: #bb4d00;
}
.default {
  fill: #000000;
}
