.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-color: white;
  border-style: solid;
  border-color: #eee;
  box-shadow: -4px 4px 0 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px;
  padding: 1.6rem;
  margin: 2rem;
  width: 40rem;
  height: 12rem;
}

.container:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.id {
  display: flex;
}

.user p {
  font-weight: 500;
  margin-left: 1rem;
  margin-top: 0.2rem;
}

.user span {
  font-weight: 300;
  margin-left: 1rem;
  margin-top: 0.2rem;
}

.userName {
  color: #6b7280;
}

.userName:hover {
  color: black;
  text-decoration: underline;
}

.cardIcon {
  font-size: 3rem;
}

.option {
  cursor: pointer;
}

.option:hover {
  color: black;
}