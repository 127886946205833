.container {
  padding-top: 3rem;
  padding-right: 20rem;
  background-color: blank;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  object-fit: contain;
  text-align: justify;
}

.container h5 {
  cursor: pointer;
}

.cloud {
  padding-top: 0;
  margin: 0;
}

.cvBtnIn {
  width: 20rem;
  height: 2.5rem;
  margin-top: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffffff;
  box-shadow: 5px 10px 30px #ccc;
  border-radius: 10px;
  color:#ffb463ff;
  cursor: pointer;
  font-weight: bold;
}

.cvBtnIn:hover {
  background-color: #eee;
  transition: all ease 0.5s;
  color:#ffb463ff;
}

.name {
  font-family: 'Montserrat';
  width: 500px;
  position: absolute;
  left: 40%;
  top: 50%;
  transform: translate(-22%,-50%);
  text-align: center;
}
