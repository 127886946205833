.container {
  background-color: blank;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  align-items: center;
  text-align: center;
  object-fit: contain;
  /* box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23); */
}

.container h2 {
  cursor: pointer;
}

.container h2:hover {
  text-decoration: underline;
}

.card {
  border-style: none;
  /* Add shadows to create the "card" effect */
  box-shadow: 0px 5px 15px 3px rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px;
  padding: 0.5rem;
  margin: 1rem;
  width: 28rem;
  min-height: 18rem;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.card input {
  margin-left: auto;
  margin-right: auto;
}

.card button {
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
}

.cardItem {
  cursor: pointer;
  padding: 0.3rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.cardItem:hover {
  background-color: #eee;
  border-radius: 10px;
}

.cardItemActive {
  cursor: pointer;
  padding: 0.3rem;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: #eee;
  border-radius: 10px;
  color: #00bcd4ff;
}

.error {
  color: red;
  margin-top: 0.5rem;
}