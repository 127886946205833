.blankField {
  background-color: #eee;
  color: black;
  font-weight: 450;
  border-style: none;
  transition: 0.3s;
  border-radius: 12px;
  padding-bottom: 2.5rem;
  padding-left: 8rem;
  padding-right: 6rem;
  margin-right: 1.5rem;
  margin-left: 4rem;
  box-shadow: 0 1.5px 1px 2px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  /* width: 10rem;
  min-height: 3rem; */
}

.blankField:hover {
  outline: none;
  background: #aaa;
}

.buttonText {
  font-size: 1.5rem;
  background-color: white;
  color: black;
  font-weight: 450;
  border-style: none;
  transition: 0.3s;
  border-radius: 12px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
  box-shadow: 0 1.5px 1px 2px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  /* width: 10rem;
  min-height: 3rem; */
}

.buttonText:hover {
  outline: none;
  background: #eee;
}

.container {
  background-color: blank;
  /* display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  align-items: center;
  text-align: center;
  overflow-y: scroll;
  object-fit: contain; */
  display: grid;
  grid-template-rows: 2fr 2fr 1fr;
  height: 100vh;
  width: 100%;
  overflow-y: scroll;
  object-fit: contain;
  font-size: 2rem;
}

.containerText {
  background-color: blank;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  overflow-y: scroll;
  object-fit: contain;
  padding: 2rem;
  font-size: 1.5rem;
}

.containerText h1 {
  margin-bottom: 2rem;
  margin-left: auto;
  margin-right: auto;
}

.containerText p {
  text-align: justify;
  margin-left: 15rem;
  margin-right: 15rem;
  margin-top: 0.7rem;
  margin-bottom: 0.7rem;
}

.containerText hr {
  text-align: justify;
  margin-left: 15rem;
  margin-right: 15rem;
  margin-top: 0.7rem;
}

.containerText img {
  width: 50%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}

.sampleGrid {
  background-color: white;
}

.sample {
  display: flex;
  margin-top: 8rem;
  justify-content: center;
}

.sample h2 {
  padding-left: 4rem;
  padding-right: 4rem;
  padding-top: 1rem;
  font-size: 2.5rem;
}

.hidden {
  background-color: white;
  border-style: none;
  padding-bottom: 2.5rem;
  margin-left: 9rem;
  margin-right: 6rem;
}

.blank {
  border-style: none;
  padding-top: 0.5rem;
}

.optionsGrid {
  background-color: white;
}

.options {
  display: flex;
}

.options div {
  margin-left: auto;
  margin-right: auto;
  margin-top: 6rem;
}

.finishGrid {
  background-color: white;
}

.finish {
  display: flex;
  margin-top: 3rem;
  margin-bottom: 3rem;
  margin-left: 10rem;
  margin-right: 10rem;
  justify-content: space-between;
}

.consequence {
  display: flex;
  margin-top: 3rem;
  margin-bottom: 3rem;
  margin-left: 10rem;
  margin-right: 10rem;
  justify-content: center;
}

.card {
  background-color: white;
  color: black;
  font-weight: 450;
  border-style: none;
  transition: 0.3s;
  border-radius: 12px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-right: 1.5rem;
  box-shadow: 0 1.5px 1px 2px rgba(0, 0, 0, 0.2);
  cursor: grab;
  /* width: 10rem;
  min-height: 3rem; */
}

.card:hover {
  outline: none;
  background: #eee;
}

.cardButton {
  background-color: white;
  color: black;
  font-weight: 450;
  border-style: none;
  transition: 0.3s;
  border-radius: 12px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  box-shadow: 0 1.5px 1px 2px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  /* width: 10rem;
  min-height: 3rem; */
}

.cardButton:hover {
  outline: none;
  background: #eee;
}

.cardCorrect {
  background-color: darkseagreen;
  color: black;
  font-weight: 450;
  border-style: none;
  transition: 0.3s;
  border-radius: 12px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  box-shadow: 0 1.5px 1px 2px rgba(0, 0, 0, 0.2);
  /* width: 10rem;
  min-height: 3rem; */
}

.cardIncorrect {
  background-color: pink;
  color: black;
  font-weight: 450;
  border-style: none;
  transition: 0.3s;
  border-radius: 12px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  box-shadow: 0 1.5px 1px 2px rgba(0, 0, 0, 0.2);
  /* width: 10rem;
  min-height: 3rem; */
}

.cardDraging {
  background-color: #eee;
  color: #eee;
  border-style: none;
  transition: 0.3s;
  border-radius: 12px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  cursor: grab;
  /* width: 10rem;
  min-height: 3rem; */
}

.dropzone {
  background-color: #eee;
  border-style: solid;
  border-color: black;
  padding-bottom: 2.5rem;
  padding-left: 8rem;
  padding-right: 6rem;
}

.dropzoneActive {
  background-color: #eee;
  border-style: solid;
  border-color: black;
  padding-bottom: 2.5rem;
  padding-left: 8rem;
  padding-right: 6rem;
}

.dropzone2 {
  background-color: #eee;
  border-style: solid;
  border-color: black;
  font-weight: 450;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  padding-left: 8rem;
  padding-right: 6rem;
}

.dropzone2Active {
  background-color: #eee;
  border-style: solid;
  border-color: black;
  font-weight: 450;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  padding-left: 8rem;
  padding-right: 6rem;
}

.iti {
  background-color: gray;
  width: 100%;
  height: 100%;
}