.container {
  position: absolute;
  left: 15rem;
  top: 4rem;
  bottom: 6px;
  right: 6px;
  border-radius: 3px;
  padding: 10px;
  box-sizing: border-box;
  overflow-y: scroll;
  /* box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23); */
}

.noSidebar {
  position: absolute;
  left: 5rem;
  top: 4rem;
  bottom: 6px;
  right: 6px;
  border-radius: 3px;
  padding: 10px;
  box-sizing: border-box;
  overflow-y: scroll;
  /* box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23); */
}
