.chartWrapper {
  height: 100vh;
  width: 100%;
  display: grid;
  grid-template-columns: auto auto;
  grid-auto-rows: 155px;
  grid-gap: 5rem;
  
}

.days {
  display: flex;
}

.days div {
  padding-right: 2rem;
}
