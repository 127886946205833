.connection {
  fill: none;
  /* stroke: #1b5e20; */
  stroke-width: 1px;
}

/* .connection {
  fill: none;
  stroke-width: 6px;
} */

/* .color0 {
  stroke: #8fcaca;
}

.color1 {
  stroke: #eceae4;
}

.color2 {
  stroke: #ffbf91;
}

.color3 {
  stroke: #d4d479;
}

.color4 {
  stroke: #ecd5e3;
}

.color5 {
  stroke: #cce2cb;
}

.color6 {
  stroke: #ffffb5;
}

.color7 {
  stroke: #97c1a9;
}

.color8 {
  stroke: #d7efef;
}

.color9 {
  stroke: #dba194;
}

.color10 {
  stroke: #c6dbda;
}

.color11 {
  stroke: #ffe3d4;
}

.color12 {
  stroke: #f6eac2;
}

.color13 {
  stroke: #e67c73;
}

.color14 {
  stroke: #c8c2ff;
}

.color15 {
  stroke: #7CB9E8;
}

.color16 {
  stroke: #72A0C1;
}

.color17 {
  stroke: #e6b2a8;
}

.color18 {
  stroke: #c9c09f;
} */
