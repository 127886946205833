html {
  scroll-behavior: smooth;
}

.container {
  height: 100vh;
  width: 100%;
  overflow-y: scroll;
}

.main {
  width:100%;
  height:768px;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('../../assets/background.png');
  overflow-y: scroll;
}

.name {
  font-family: 'Montserrat';
  width: 500px;
  position: absolute;
  left: 40%;
  top: 50%;
  transform: translate(-22%,-50%);
  text-align: center;
}

.name .details{
  color: #969696;
}

.name h1 {
  font-family:'Montserrat';
  font-size: 2rem;
  letter-spacing: 2px;
  color:#000000;
  padding-bottom: 2rem;
}

.name h2 {
  font-size: 2rem;
  color:#00b7ff;
  font-weight: 500;
  padding-top: 2rem;
}

.name p {
  padding-top: 1rem;
  font-size: 1.2rem;
  font-weight: 500;
}

.name button {
  margin-left: auto;
  margin-right: auto;
}

.cvBtn {
  width: 20rem;
  height: 2.5rem;
  margin-top: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00b7ff;
  box-shadow: 5px 10px 30px rgba(2, 238, 255, 0.336);
  border-radius: 10px;
  color:#ffffff;
  cursor: pointer;
}

.cvBtn:hover {
  background-color: #0099ff;
  transition: all ease 0.5s;
  color: #ffffff;
}

.aContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: center;
  align-content: center;
}

.abImg {
  width: 100%;
  height: 60%;
}
.abImg img {
  padding: 15px;
  width:100%;
  height: 100%;
}

.sbText {
  width: 100%;
  height: 40%;
  background-color:#ffffff;
  display:flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
}
.sbText p {
  margin: 0px;
  color:#000266a6;
  font-size: 1.1rem;
  font-family: calibri;
  display: block;
  display: -webkit-box;
  max-width: 80%;
  margin-top: 25px;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.sbText p:hover {
  color:#000266;
}

.sbText a {
  margin-top:15px ;
}

.aboutImage{
  width: 50%;
}

.aboutImage img{
  width: 600px;
}

#about{
  margin-top:100px ;
  width:100%;
  height: 120vh;
  background-color:#f3fdff;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 50px 5% 0px 5%;
  position: relative;
  }

.aboutText {
  width: 45%;
  list-style: circle;
}

.aboutText h2 {
  font-size: 1.7rem;
  color:#00b7ff;
  font-weight: 500;
  margin: 0px;
  padding: 0px;
  padding-top: 1rem;
}

.aboutText p {
  width: 80%;
  font-size: 1.2rem;
  font-weight: 500;
  padding-top: 1rem;
  text-align: justify;
}

.aboutText button {
  margin-top: 20px;
  width: 20rem;
  height: 2.5rem;
  border-radius: 10px;
  border: none;
  outline: none;
  color: #ffffff;
  background-color:#00b7ff;
  cursor: pointer;
}

.aboutText button:hover {
  background-color: rgb(0, 132, 255);
  transition: all ease 0.3s;
  color: #ffffff;
}

#purpose {
  padding-top: 30px;
  width:100%;
  height:768px;
  position: relative;
  box-sizing: border-box;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* background-image: url(../../assets/presentation.png); */
  text-align: justify;
}

#purpose h2 {
font-size: 1.7rem;
padding-top: 10rem;
margin-left: 20rem;
}

#purpose p {
  padding-top: 2rem;
  font-size: 1.2rem;
  font-weight: 500;
  margin-left: 20rem;
  margin-right: 20rem;
}

.features {
  width:100%;
  height:100vh;
  box-sizing: border-box;
  font-family: calibri;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
}

.features h1 {
  font-size: 2rem;
  color:#00b7ff;
  font-weight: 500;
  padding-bottom: 4rem;
}

.features img {
  margin-left: auto;
  margin-right: auto;
  width: 200px;
}

#contact {
  background-color: #00b7ff;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem 0 8rem 0;
}

#contact h3 {
  font-size:2.5rem;
  margin:20px;
  color: #fff;
}

#contact p {
  font-size: 1.5rem;
  margin:20px;
  color: #fff;
}

#contact li {
  font-size: 1rem;
  margin:20px;
  margin-top: 5rem;
  color: #fff;
}

.contactInput {
  width:400px;
  height:50px;
  background-color:#FFFFFF;
  display:flex;
  justify-content: center;
  border-radius: 50px;
  box-shadow: 2px 2px 30px rgba(0,0,0,0.15);
}

.contactInput input {
  width:100%;
  background-color: transparent;
  border:none;
  outline: none;
  text-align: center;
  color:#242425;
}

.contactInput a {
  width:200px;
  height:35px;
  background-color:#00b7ff;
  color:#FFFFFF;
  display: flex;
  font-size: 0.9rem;
  justify-content: center;
  align-items: center;
  margin: auto 10px;
  border-radius: 20px;
  font-weight: 500;
  transition: 0.4s ease-out;
}

.contactInput a:hover {
  background-color:#0099ff;
}

@media(max-width:1190px){
  .main {
    background-size: 1150px !important;
  }
  .name {
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    text-align: center;
  }
  #purpose h2 {
margin-left: 2rem;
  }
  #purpose p {
margin-left: 2rem;
  }
  .features {
    height:auto;
  }
  .features {
    margin-bottom: 100px;
  }
  .aContainer {
    display: flex;
    flex-wrap: wrap;
  }
  .aBox {
    flex-grow: 1;
  }
  .abImg img {
    object-fit:contain;
  }
  .aboutImage img{
    height:400px;
  }
}

@media(max-width:970px) {
  /* .main{
    background-image: none !important;
  } */
  /* .cvBtn {
    margin-left: 27%;
    margin-top: 20px;
  } */
  .name {
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
  }
  #about {
    justify-content: center;
    padding-top: 0px;
    text-align: center;
    margin-top: 0px;
  }
  .aboutText button {
    justify-content: center;
    padding-top: 0px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
  .aboutImage {
    display:none;
  }
  .aboutText {
    width: 90%;
  }
  .aboutText h1 {
    font-size:4rem;
  }
  .aboutText p {
    width: 100%;
  }
  #purpose {
    width: 90%;
    justify-content: center;
    padding-top: 0px;
    margin-top: 0px;
  }
  #purpose h2 {
    margin-left: 1rem;
  }
  #purpose p {
    width: 100%;
    margin-left: 1rem;
  }
}

@media(max-width:600px) {
  .name {
    width:60%;
    }
  }
