.sidebar {
  flex: 1;
  height: calc(100vh - 50px);
  background-color: rgb(251, 251, 255);
  position: sticky;
  top: 50px;
  text-align: center;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(107, 114, 128, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

/* Remove scrollbar space */
/* Optional: just make scrollbar invisible */
/* ::-webkit-scrollbar {
  width: 0;
  background: transparent;
} */

.sidebarWrapper {
  padding: 20px;
  color: #555;
}

.sidebarMenu {
  margin-bottom: 10px;
}

.sidebarTitle {
  font-size: 13px;
  color: rgb(187, 186, 186);
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.sidebarList {
  list-style: none;
  padding: 5px;
}

.sidebarListItem {
  padding: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 10px;
}

.sidebarListItem:hover {
  background-color: #cccccc44;
}

.sidebarListItem.active {
  background-color: #0097a722;
}

.sidebarListItem.disable {
  color: #aaa;
}

.sidebarIcon {
  font-size: 0.9rem;
  padding-right: 16px;
  color: #4b5563;
}

.sidebarIcon.disable {
  color: #aaa;
}

.copyright {
  height: 40px;
  width: 100%;
  position: absolute;
  bottom: 0;
}

.userButton {
  background: #eee;
  border-radius: 50%;
  height: 60px;
  width: 60px;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid transparent;
  transition: 0.1s;
  cursor: pointer;
}

.userButton::after {
  content: "";
  display: block;
  width: 24px;
  height: 24px;
  background: url("../../assets/person.svg") no-repeat center center;
}

.userButton:focus,
.userButton:hover,
.userButtonActive {
  outline: none;
  background: #eee;
  border: 5px darkgray;
}
