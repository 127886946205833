/* .container {
  fill: white;
  fill-opacity: 0.9;
  stroke: none;
  paint-order: stroke;
  cursor: pointer;
}

.background {
  border: none;
  fill: white;
  fill-opacity: 0.9;
  paint-order: stroke;
  cursor: pointer;
}

.text {
  font-size: 2rem;
  fill: black;
  cursor: pointer;
}

.left {
  text-anchor: end;
}

.middle {
  text-anchor: middle;
}

.right {
  text-anchor: start;
}

.selected {
  stroke-width: 2px;
  stroke: blue;
}

.icon {
  font-size: 3rem;
} */


.container {
  /* fill: white; */
  fill-opacity: 0.9;
  /* stroke: #1b5e20; */
  paint-order: stroke;
  cursor: pointer;
}

.text {
  font-size: 10px;
}

.textBold0 {
  font-size: 12px;
  font-weight: 600;
}

.textBold1 {
  font-size: 12px;
  font-weight: 500;
  fill: green
}

.textBold2 {
  font-size: 12px;
  font-weight: 500;
  fill: orange
}

.textBold3 {
  font-size: 12px;
  font-weight: 500;
  fill: red
}

.left {
  text-anchor: end;
}

.middle {
  text-anchor: middle;
}

.right {
  text-anchor: start;
}

.selected {
  stroke-width: 2px;
  animation: pulse infinite 1s linear;
}

